<template>
  <div class="notice">

    <van-nav-bar title="购票须知" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="$router.go(-1)" />
      </template>
    </van-nav-bar>
    <div class="nt_cont" v-html="notice"></div>
  </div>
</template>

<script src="./notice.js"></script>

<style scoped src="./notice.css"></style>
